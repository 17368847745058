<script>
// import axios from "axios";
import Layout from "../../layouts/auth";
// import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";
import axios from 'axios';
import { required, email, helpers, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

// import base_domain from '../../myvars'
// axios.defaults.baseURL = base_domain

/**
 * Forgot Password component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    email: {
      required: helpers.withMessage("Email is required", required),
      email: helpers.withMessage("Please enter valid email", email),
    },
    form2: {
      copiedEmail: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      otp: {
        required: helpers.withMessage("OTP is required", required),
        maxLength: maxLength(4),
      },
    }
  },
  page: {
    title: "Forgot Password",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,

  },
  data() {
    return {
      email: '',
      form2: {
        otp: '',
        copiedEmail: '',
      },
      showForm1: true, // Initially show the first form
      showForm2: false,
      isLoading: false,
      isDisabled: false,
      submitted: false,
      isResetError: false,
      isSuccess: false,
      success: "",
      error: '',
      spinner: false,
      url: process.env.VUE_APP_URL
    };
  },
  methods: {
    tryToReset() {
      this.submitted = true;
      this.v$.email.$touch();
      if (this.v$.email.$invalid) {
        return;
      } else {
        this.isLoading = true;
        this.isDisabled = true;
        axios.post(this.url + 'api/generateOTP', { email: this.email })
          .then(() => {
            // Handle the response
            this.form2.copiedEmail = this.email;
            this.showForm1 = false;
            this.showForm2 = true;
            this.isSuccess = true;
            this.success = "OTP sent entered email successfully";
            setTimeout(() => {
              this.isSuccess = false;
              this.success = "";
            }, 2000)
          })
          .catch(error => {
            // this.isResetError=true;
            // Handle any errors
            console.error('Error sending email:', error);
          });
      }
    },
    tryToLogin() {
      this.submitted = true;
      this.v$.form2.$touch();
      if (this.v$.form2.$invalid) {
        return;
      } else {
        this.spinner = true;
        document.getElementById("loginBtn").disabled  = true;
        //  this.form.personal1.email  = this.copiedEmail 
        axios.post(this.url + 'api/verifyOTP', { email: this.form2.copiedEmail, otp: this.form2.otp })
          .then(response => {
            console.log('Login sent successfully:');
            localStorage.setItem('email', JSON.stringify(encodeURIComponent(btoa(response.data.data.email))));
            localStorage.setItem('userType', JSON.stringify(encodeURIComponent(btoa(response.data.data.userType))));
            localStorage.setItem('authenticatedUserId', JSON.stringify(encodeURIComponent(btoa(response.data.data.id))));
            if (response.data.checkUser == true) {
              this.$router.push({ path: '/dashboard' });
            } else {
              this.$router.push({ path: '/citizenship_application' });
            }
          })
          .catch(error => {
            // Handle any errors
            this.spinner = false;
            document.getElementById("loginBtn").disabled  = false;
            if (error.response.data.errors.email) {
              this.isResetError = true;
              this.error = error.response.data.errors.email[0];
              setTimeout(() => {
                this.isResetError = false;
                this.error = null;
              }, 2000)
            } else {
              this.isResetError = true;
              this.error = error.response.data.errors;
              setTimeout(() => {
                this.isResetError = false;
                this.error = "";
              }, 2000)
            }
            console.error('Error sending email:', error);
          });
      }
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      // if(/[^0-9\s]+/.test(char)) return true; // Match with regex
      // else e.preventDefault(); // If not match, don't add to input
      if (/[^0-9]/.test(char)) {
        e.preventDefault();
      }
    },

  }

};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="card-body pt-0">
            <div>
            </div>

            <div class="p-2">
              <div class="text-center py-3">
                <img src="@/assets/images/citizenship/whinchester.png" alt="Whinchester" height="80" />
              </div>
              <b-alert v-model="isResetError" class="mb-4" variant="danger" dismissible>{{ error }}</b-alert>
              <b-alert v-model="isSuccess" class="mb-4" variant="success" dismissible>{{ success }}</b-alert>
              <form v-if="showForm2">
                <div class="mb-3">
                  <label for="useremail">Email</label>
                  <input type="email" v-model="form2.copiedEmail" class="form-control" id="useremail"
                    placeholder="Enter email" :class="{ 'is-invalid': submitted && v$.form2.copiedEmail.$error, }"
                    :disabled="true" />
                  <div v-for="(item, index) in v$.form2.copiedEmail.$errors" :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="otpId">One Time Password</label>
                  <input type="number" v-on:keypress="isNumber($event)" v-model="form2.otp" class="form-control"
                    id="otpId" placeholder="Enter OTP" :class="{
                      'is-invalid': submitted && v$.form2.otp.$error,
                    }" />
                  <div v-for="(item, index) in v$.form2.otp.$errors" :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                  <div class="pt-2">
                    Didn't get the code?
                    <button @click.prevent="tryToReset" class="btn text-theme p-0 text-decoration-underline w-md"
                      type="button">Click to Resend</button>
                  </div>
                </div>
                <div class="mb-3 row mb-0">
                  <div class="col-12 text-end">
                    <button @click.prevent="tryToLogin" id="loginBtn" class="btn btn-theme w-md" type="button">
                      Submit<span class="spinner-border spinner-border-sm " v-if="spinner" role="status"
                        aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
              </form>
              <form v-if="showForm1">
                <div class="mb-3">
                  <label for="useremail">Email</label>
                  <input type="email" v-model="email" class="form-control" id="useremail" placeholder="Enter email"
                    :class="{ 'is-invalid': submitted && v$.email.$error }" :disabled="isDisabled" />
                  <div v-for="(item, index) in v$.email.$errors" :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                  <div v-if="isLoading">
                    <label for="useremail">Please wait...</label>
                  </div>
                </div>

                <div class="mb-3 row mb-0">
                  <div class="col-12 text-end">
                    <button @click.prevent="tryToReset" class="btn btn-theme w-md" type="button">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            © {{ new Date().getFullYear() }} Whinchester. Crafted with by Midriff
          </p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>


<style lang="scss" module></style>
